import React from "react"
import ReactDOMServer from 'react-dom/server';

export default function PdfIcon({
    color = "white",
    size = 40
   }) {

  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M13.8469 24.5879c-.3082 0-.5159.0301-.6231.0603v1.9731c.1273.0301.2864.0385.5058.0385.8023 0 1.2965-.4053 1.2965-1.0904 0-.613-.4255-.9815-1.1792-.9815zm5.8406.0201c-.335 0-.5528.0301-.6817.0603v4.3716c.1289.0302.3366.0302.5242.0302 1.3685.01 2.2595-.7437 2.2595-2.3383.0101-1.3902-.8023-2.1238-2.102-2.1238z" />
      <path fill={color} d="M23.4494 3.34961H10.0497a3.34991 3.34991 0 00-3.34987 3.34992V33.4989c0 .8884.35294 1.7405.98117 2.3687a3.35004 3.35004 0 002.3687.9812h20.0995a3.35006 3.35006 0 003.35-3.3499V13.3994L23.4494 3.34961zM15.9087 27.1173c-.5175.4857-1.2813.7035-2.1707.7035a3.75502 3.75502 0 01-.5159-.0302v2.3885h-1.4974v-6.5926a12.6596 12.6596 0 012.0418-.1374c.9329 0 1.5962.1776 2.0434.5343.4255.3384.7136.8928.7136 1.546-.0017.6566-.2195 1.211-.6148 1.5879zm6.3766 2.2695c-.7035.5846-1.7738.8626-3.0819.8626-.7839 0-1.3383-.0502-1.7152-.1005v-6.5608a13.32417 13.32417 0 012.0418-.139c1.268 0 2.092.2278 2.7352.7135.6951.5159 1.1306 1.3383 1.1306 2.5192 0 1.278-.4673 2.1607-1.1105 2.705zm6.189-4.648h-2.566v1.5259h2.3985v1.2294h-2.3985v2.6867h-1.5176v-6.6814h4.0836v1.2394zm-5.0249-9.6645h-1.6749V6.69953l8.3747 8.37477h-6.6998z" />
    </svg>
  )
}
