import React from "react"
import styled from "styled-components"

const Iframe = styled.iframe`
  display: block;
`

const PodcastFrame = ({ id, ...props }) => {
  if (!id) {
    return null
  }

  const src = `${id.replace("shows.acast.com", "embed.acast.com").replace("/episodes", "").split("?")[0]}?theme=light&cover=false`

  return (
    <Iframe
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      width="100%"
      height="450"
      src={src}
      {...props}
    />
  )
}

export default PodcastFrame
